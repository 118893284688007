.project-container {
	display: flex;
	flex-flow: row wrap;
	flex: 1 1 100%;
	width: 90%;
	margin-right: auto;
	margin-left: auto;
}

.project__label {
	margin-bottom: 1rem;
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size: 1.05rem;
	font-weight: 400;
}

.project__title.header {
	font-size: 1.8rem;
}

.project__image {
	width: 100%;
	height: 100%;
	margin: 2rem 0;
}

.project__text {
	line-height: 1.7;
	font-size: 1.1rem;
	margin: 0.5rem 0;
}

.project__list .list__item {
	margin-bottom: 0.75rem;
}

.project__links {
	display: flex;
	flex-flow: row;
	align-content: center;
	justify-content: center;
	margin-top: 2rem;
}

.project__links .links__arrow {
	display: flex;
	flex: 1 1 50%;
	height: 100px;
	color: white;
	font-size: 1.25rem;
	transition: background-color 0.3s ease;
}

.project__links .links__arrow:hover {
	color: white;
}

.project__links .links__arrow.left:hover,
.project__links .links__arrow.right:hover {
	background: #c75820;
}

.project__links .links__arrow.left {
	background: var(--highlight-color);
	padding-left: 5%;
	justify-content: flex-start;
}

.project__links .links__arrow.right {
	background: rgb(0, 160, 214);
	padding-right: 5%;
	justify-content: flex-end;
}

.project__links .links__text {
	margin-top: auto;
	margin-bottom: auto;
}

@media screen and (min-width: 768px) {
	.project-container {
		width: 65%;
		max-width: 1000px;
	}

	.project__title.header {
		font-size: 2rem;
	}

	.project__image {
		margin: 3.5rem 0;
	}

	.project__text {
		font-size: 1.3rem;
		margin: 0.75rem 0;
	}

	.project__links {
		margin-top: 4rem;
	}

	.project__links .links__arrow {
		height: 150px;
		font-size: 1.5rem;
	}
}

@media screen and (min-width: 1600px) {
	.project__title.header {
		font-size: 2.3rem;
	}

	.project__text {
		font-size: 1.5rem;
	}

	.project__links .links__arrow.left {
		padding-left: 0;
		justify-content: center;
	}

	.project__links .links__arrow.right {
		padding-right: 0;
		justify-content: center;
	}
}
