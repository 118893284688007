body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.nav {
	font-size: 1.2rem;
	margin-top: 0.75rem;
}

.nav__list-item {
	margin-top: 1rem;
	margin-left: 3.5rem;
}

.nav__list-item:first-child {
	margin-left: 0;
}

.nav__logo {
	height: 3.5rem;
	width: 3.5em;
	-webkit-transform: rotate(180deg) scaleX(-1);
	        transform: rotate(180deg) scaleX(-1);
	-webkit-filter: invert(39%) sepia(0%) saturate(50%) hue-rotate(158deg) brightness(91%)
		contrast(95%);
	        filter: invert(39%) sepia(0%) saturate(50%) hue-rotate(158deg) brightness(91%)
		contrast(95%);
	transition: -webkit-filter 0.3s ease;
	transition: filter 0.3s ease;
	transition: filter 0.3s ease, -webkit-filter 0.3s ease;
	/*opacity: 0.6;*/
	/*filter: invert(47%) sepia(97%) saturate(2120%) hue-rotate(161deg) brightness(94%) contrast(101%); #00a4d6 */
	/*filter: invert(40%) sepia(82%) saturate(1025%) hue-rotate(167deg) brightness(92%) contrast(104%); #0091d6*/
}

.nav__logo:hover {
	-webkit-filter: invert(40%) sepia(82%) saturate(1025%) hue-rotate(167deg)
		brightness(92%) contrast(104%);
	        filter: invert(40%) sepia(82%) saturate(1025%) hue-rotate(167deg)
		brightness(92%) contrast(104%);
}

.hidden-mobile {
	display: none;
}

@media screen and (min-width: 460px) {
	.hidden-mobile {
		display: unset;
	}
}

@media screen and (min-width: 768px) {
	.nav {
		font-size: 1.4rem;
		margin-top: 2.5rem;
	}

	.nav__logo {
		height: 4.5rem;
		width: 4.5rem;
		/*filter: invert(47%) sepia(97%) saturate(2120%) hue-rotate(161deg) brightness(94%) contrast(101%); #00a4d6 */
		/*filter: invert(40%) sepia(82%) saturate(1025%) hue-rotate(167deg) brightness(92%) contrast(104%); #0091d6*/
	}
}

.hero .container.personal {
	margin-bottom: 0.5rem;
}

.hero__employer {
	flex: 1 0;
}

.hero__headshot {
	display: none;
	width: 212px;
	height: 212px;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
	margin-top: 0.5em;
}

.hero__headshot-image {
	display: inline;
	margin: 0 auto;
	width: 100%;
	height: auto;
}

.employer__label {
	margin-bottom: 1em;
	letter-spacing: 1.4px;
	text-transform: uppercase;
	font-size: 1.05rem;
	font-weight: 400;
}

.employer__logo {
	position: relative;
	transition: -webkit-transform ease 0.2s;
	transition: transform ease 0.2s;
	transition: transform ease 0.2s, -webkit-transform ease 0.2s;
	margin-bottom: 2em;
	margin-right: 4em;
}
.employer__logo.last {
	margin-bottom: 0;
}
.employer__logo:hover {
	-webkit-transform: translateY(-2px);
	        transform: translateY(-2px);
	-webkit-filter: none;
	        filter: none;
	opacity: 1;
}

.kpmgdv-logo {
	opacity: 0.65;
	width: 225px;
}

.decolourize-logo {
	-webkit-filter: brightness(0%);
	        filter: brightness(0%);
	opacity: 0.65;
	width: 200px;
}

@media screen and (min-width: 768px) {
	.hero .container.personal {
		margin-bottom: 2.5rem;
	}
}

@media screen and (min-width: 1186px) {
	.hero__headshot {
		display: inline;
	}
	.employer__logo {
		margin-bottom: 0;
	}
}

.featured-work {
	background: var(--work-bg);
	text-align: left;
	color: white;
}

.featured-work-container {
	display: flex;
	flex-flow: row wrap;
	flex: 1 1 100%;
}

.work-link {
	display: inline-block;
	flex: 1 1 100%;
	text-decoration: none;
}

.work-link-wrapper {
	flex: 1 0;
	margin: 15px;
	background: white;
	text-align: center;
	transition: all 0.2s ease;
}

.work-link-wrapper:hover {
	-webkit-transform: translateY(-3px);
	        transform: translateY(-3px);
	/*box-shadow: 0 8px 14px rgba(93,93,93,.1);*/
	box-shadow: 0 8px 14px rgba(182, 182, 182, 0.6);
}

.work-link-wrapper__thumb {
	width: 100%;
}

.work-link-wrapper__title {
	font-size: 1.5rem;
	text-decoration: none;
	color: var(--default-color);
	font-weight: 400;
	margin: 0;
	padding: 1rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
}

.work-link-wrapper__content {
	font-size: 1.1rem;
	text-decoration: none;
	color: var(--default-color);
	margin: 0;
	padding: 1rem 1rem;
	padding-top: 0;
}

.additional-work {
	background: var(--alternate-bg);
	text-align: left;
	color: var(--default-text);
}

.additional-work-container {
	display: flex;
	flex-flow: column;
	flex: 1 1 100%;
	background: var(--alternate-bg);
	color: var(--default-color);
}

.work__list {
	list-style-type: none;
	padding-left: 0;
	margin: 0;
}

.work__list-item {
	padding: 18px 5% 20px;
	border-bottom: 1px solid #dadada;
	transition: all 0.3s ease;
	color: var(--default-color);
}

.work__list-item:hover {
	background: var(--highlight-color);
	color: white;
	-webkit-transform: translateY(-3px);
	        transform: translateY(-3px);
	border-color: transparent;
	box-shadow: 0 1px 6px rgba(93, 93, 93, 0.16);
	background: linear-gradient(90deg, #0091d6, #3da1db, #0091d6);
}

.work__list-item-link {
	display: flex;
	text-decoration: none;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	align-items: flex-start;
}

.work__list-item-link .work-title {
	flex: 3 1 100%;
	font-weight: 400;
	letter-spacing: 1.5px;
	color: var(--highlight-color);
	margin: 0;
	margin-bottom: 0.5em;
}

.work__list-item:hover .work-title {
	color: white;
}

.work__list-item-link .work-detail {
	font-size: 1.1rem;
	padding: 0.25em 0;
	transition: color 0.2s ease;
}

.work__list-item:hover .work-detail {
	color: white;
}

.work__list-item-link .work-detail.work-role {
	flex: 1 1 100%;
}

.work__list-item-link .work-detail.work-spacer {
	display: none;
}

.work__list-item-link .work-detail.work-company {
	flex: 1 1 100%;
}

.work__list-item-link .work-detail.work-date {
	flex: 1 1 100%;
}

@media screen and (min-width: 768px) {
	.featured-work-container {
		padding-left: 2em;
		padding-right: 2em;
		max-width: 1300px;
		margin-left: auto;
		margin-right: auto;
	}

	.work-link {
		flex: 0 0 50%;
	}

	.work-link-wrapper__title {
		font-size: 1.75rem;
	}

	.work-link-wrapper__content {
		padding: 1rem 2.5rem;
		padding-top: 0;
	}

	.work__list-item-link {
		max-width: 1200px;
	}

	.work__list-item-link .work-detail.work-role {
		flex: 1 1 52.5%;
	}

	.work__list-item-link .work-detail.work-spacer {
		flex: 1 1 7.5%;
		display: inline;
	}

	.work__list-item-link .work-detail.work-company {
		flex: 1 1 30%;
	}

	.work__list-item-link .work-detail.work-date {
		flex: 1 1 10%;
		justify-self: flex-end;
		text-align: right;
	}
}

@media screen and (min-width: 1600px) {
	.featured-work-container {
		max-width: 1600px;
	}

	.work__list-item-link {
		max-width: 1400px;
	}
}

.contact {
	margin-bottom: 40vh;
}

.contact__title {
	flex: 1 1 100%;
}

.social-list {
	padding-left: 0;
	margin: 0;
	display: flex;
	flex-flow: row wrap;
}

.social-list__item {
	padding-right: 2rem;
}

.project-container {
	display: flex;
	flex-flow: row wrap;
	flex: 1 1 100%;
	width: 90%;
	margin-right: auto;
	margin-left: auto;
}

.project__label {
	margin-bottom: 1rem;
	letter-spacing: 3px;
	text-transform: uppercase;
	font-size: 1.05rem;
	font-weight: 400;
}

.project__title.header {
	font-size: 1.8rem;
}

.project__image {
	width: 100%;
	height: 100%;
	margin: 2rem 0;
}

.project__text {
	line-height: 1.7;
	font-size: 1.1rem;
	margin: 0.5rem 0;
}

.project__list .list__item {
	margin-bottom: 0.75rem;
}

.project__links {
	display: flex;
	flex-flow: row;
	align-content: center;
	justify-content: center;
	margin-top: 2rem;
}

.project__links .links__arrow {
	display: flex;
	flex: 1 1 50%;
	height: 100px;
	color: white;
	font-size: 1.25rem;
	transition: background-color 0.3s ease;
}

.project__links .links__arrow:hover {
	color: white;
}

.project__links .links__arrow.left:hover,
.project__links .links__arrow.right:hover {
	background: #c75820;
}

.project__links .links__arrow.left {
	background: var(--highlight-color);
	padding-left: 5%;
	justify-content: flex-start;
}

.project__links .links__arrow.right {
	background: rgb(0, 160, 214);
	padding-right: 5%;
	justify-content: flex-end;
}

.project__links .links__text {
	margin-top: auto;
	margin-bottom: auto;
}

@media screen and (min-width: 768px) {
	.project-container {
		width: 65%;
		max-width: 1000px;
	}

	.project__title.header {
		font-size: 2rem;
	}

	.project__image {
		margin: 3.5rem 0;
	}

	.project__text {
		font-size: 1.3rem;
		margin: 0.75rem 0;
	}

	.project__links {
		margin-top: 4rem;
	}

	.project__links .links__arrow {
		height: 150px;
		font-size: 1.5rem;
	}
}

@media screen and (min-width: 1600px) {
	.project__title.header {
		font-size: 2.3rem;
	}

	.project__text {
		font-size: 1.5rem;
	}

	.project__links .links__arrow.left {
		padding-left: 0;
		justify-content: center;
	}

	.project__links .links__arrow.right {
		padding-right: 0;
		justify-content: center;
	}
}

:root {
	--default-color: #5d5d5d;
	--highlight-color: #0091d6;
	--default-bg: white;
	--alternate-bg: #fafafa;
	--work-bg: #282c34;
}

.App {
	font-family: "Barlow", Helvetica, Arial, sans-serif;
	color: #5d5d5d;
	color: var(--default-color);
}

section {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

section:first-child {
	padding-top: 2rem;
}

a {
	text-decoration: none;
	color: #5d5d5d;
	color: var(--default-color);
}

a:hover:not(.no-default-hover) {
	color: #0091d6;
	color: var(--highlight-color);
}

a.inverse {
	text-decoration: none;
	color: #0091d6;
	color: var(--highlight-color);
}

a.inverse:hover:not(.no-default-hover) {
	color: #5d5d5d;
	color: var(--default-color);
}

.header {
	font-size: 1.9rem;
	font-weight: 400;
	line-height: 1.75;
	max-width: 840px;
	letter-spacing: 1px;
	margin-bottom: 1rem;
	margin-top: 0;
}

.header a {
	transition: color 0.25s ease;
}

.container {
	display: flex;
	flex-flow: row wrap;
	width: 90%;
	margin-right: auto;
	margin-left: auto;
}

.container.container--vertically-center {
	align-items: center;
}

.container.container--horizontally-spaced {
	justify-content: space-between;
}

.container.container--wrap {
	flex-wrap: wrap;
}

.flexItem-0 {
	flex: 0 1;
}

.flexItem-1 {
	flex: 1 1;
}

.flexItem-2 {
	flex: 2 1;
}

@media screen and (min-width: 768px) {
	.container {
		max-width: 1200px;
	}

	section {
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}

	section:first-child {
		padding-top: 3.5rem;
	}

	.header {
		font-size: 2.2rem;
		line-height: 2;
	}
}

@media screen and (min-width: 1600px) {
	section {
		padding-top: 5.5rem;
		padding-bottom: 5.5rem;
	}

	section:first-child {
		padding-top: 5.5rem;
	}

	.container {
		max-width: 1400px;
	}

	.header {
		font-size: 2.4rem;
		line-height: 2;
	}
}

